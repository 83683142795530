import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxsModule } from '@ngxs/store';
import { GoogleChartsModule } from 'angular-google-charts';

import { CommonModule } from '@angular/common';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { DashboardState } from './store/dashboard.state';
import { DashboardRoutingModule } from './dashboard.routing.module';
import { ShortcutsComponent } from './components/shortcuts/shortcuts.component';
import { MaterialModule } from '../material';
import { ChartsComponent } from './components/charts/charts.component';
import { LogsComponent } from './components/logs/logs.component';

@NgModule({
  declarations: [
    DashboardComponent,
    ShortcutsComponent,
    ChartsComponent,
    LogsComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    GoogleChartsModule,
    DashboardRoutingModule,
    FlexLayoutModule,
    NgxsModule.forFeature([DashboardState]),
  ],
  providers: [],
  exports: [
    LogsComponent,
  ],
})
export class DashboardModule {
}
