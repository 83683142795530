import { Injectable } from '@angular/core';
import {
  Action, Selector, State,
  StateContext,
} from '@ngxs/store';
import { map, tap } from 'rxjs/operators';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Params } from '@angular/router';
import { ResetForm, UpdateFormValue } from '@ngxs/form-plugin';
import { StepModel } from '../models/step.model';
import { StepManagementDataService } from '../services/step-management-data.service';
import { SnackbarService } from '../../shared/services/snack-bar.service';
import { RouterSelectors } from '../../core/store/router.selectors';
import {
  CreateStepManagement,
  CreateStepManagementSuccess, DeleteImage, DeleteObject,
  DeleteStep,
  DeleteStepSuccess, GetImageList, GetImageListSuccess, GetObjectList, GetObjectListSuccess,
  GetStepManagementData,
  GetStepManagementDataSuccess,
  GetStepManagementList,
  GetStepManagementListSuccess,
  ResetStepManagementData,
  SetStepImage,
  SetStepObject,
  UpdateStepManagementData,
  UpdateStepManagementDataSuccess, UploadStepImage, UploadStepObject,
} from './actions/step-management.actions';
import { GetInstructionManagementData } from '../../instruction-management/store/actions/instruction-management.actions';
import { ImageModel } from '../models/image.model';
import { ObjectModel } from '../models/object.model';

const InitialStepDetailsForm = {
  model: {
    header: null,
    body: null,
    paths: [

      {
        id: null,
        stepId: null,
        event: 'SUCCESS',
      },
      {
        id: null,
        stepId: null,
        event: 'FAIL',
      },
    ],
  },
  dirty: false,
  status: '',
  errors: {},
};

interface StepManagementStateModel {
  imageList: ImageModel[];
  objectList: ObjectModel[];
  stepList: StepModel[];
  stepData: StepModel;
  stepDetailsForm: {
    model: any;
    dirty: boolean;
    status: string;
    errors: any;
  }
}

const defaultState: StepManagementStateModel = {
  imageList: null,
  objectList: null,
  stepList: null,
  stepData: null,
  stepDetailsForm: InitialStepDetailsForm,
};

@State<StepManagementStateModel>({
  name: 'stepManagement',
  defaults: defaultState,
})

@Injectable()
export class StepManagementState {

  constructor(
    private stepManagementDataService: StepManagementDataService,
    private snackbarService: SnackbarService,
  ) {
  }

  @SelectSnapshot(RouterSelectors.params) params: Params;

  @Selector()
  static stepManagementList(state: StepManagementStateModel) {
    return state.stepList;
  }

  @Selector()
  static imageList(state: StepManagementStateModel) {
    return state.imageList;
  }

  @Selector()
  static objectList(state: StepManagementStateModel) {
    return state.objectList;
  }

  @Selector()
  static stepData(state: StepManagementStateModel) {
    return state.stepData;
  }

  @Selector()
  static stepDiagramXml(state: StepManagementStateModel) {
    return state.stepData;
  }

  @Selector()
  static permissions(state: StepManagementStateModel) {
    // return state.stepData.permissions;
  }


  @Action(GetStepManagementList)
  getStepManagementList(ctx: StateContext<StepManagementStateModel>) {
    const userId = JSON.parse(localStorage.getItem('userData')).id;
    return this.stepManagementDataService.getStepManagementListData(userId).pipe(
      tap((result) => {
        ctx.dispatch(new GetStepManagementListSuccess(result.data));
      }),
    );
  }

  @Action(GetStepManagementListSuccess)
  getStepManagementListSuccess(ctx: StateContext<StepManagementStateModel>, action: GetStepManagementListSuccess) {
    ctx.patchState({
      stepList: action.stepList,
    });

  }

  @Action(ResetStepManagementData)
  resetStepManagementData(ctx: StateContext<StepManagementStateModel>) {
    ctx.patchState({
      stepData: null,
    });
    ctx.dispatch(new ResetForm({
      value: InitialStepDetailsForm.model,
      path: 'stepManagement.stepDetailsForm',
    }));

  }

  @Action(GetStepManagementData)
  getStepManagementData(ctx: StateContext<StepManagementStateModel>, action: GetStepManagementData) {
    return this.stepManagementDataService.getStepManagementById(action.stepId).pipe(
      tap((result) => {
        ctx.dispatch(new GetStepManagementDataSuccess(result.data));
      }),
    );
  }

  @Action(GetStepManagementDataSuccess)
  getStepManagementDataSuccess(ctx: StateContext<StepManagementStateModel>, action: GetStepManagementDataSuccess) {
    let stepForm = this.resetFormPaths(ctx, action);
    this.resetStepData(ctx);

    ctx.patchState({
      stepData: action.stepData,
    });

    setTimeout(() => {
      ctx.dispatch(new UpdateFormValue({
        value: stepForm,
        path: 'stepManagement.stepDetailsForm',
      }));
    });
  }

  @Action(UpdateStepManagementData)
  updateStepManagementData(ctx: StateContext<StepManagementStateModel>, action: UpdateStepManagementData) {
    const stepFormData = ctx.getState().stepDetailsForm.model;
    const imageList = ctx.getState().imageList;
    const objectList = ctx.getState().objectList;
    const selectedImage = imageList.find((image) => image.name === stepFormData.picture)
    const selectedModel = objectList.find((model) => model.name === stepFormData.model)

    stepFormData.paths = stepFormData.paths.filter((path) => path.stepId);

    delete stepFormData.picture;
    delete stepFormData.model;
    stepFormData.pictureId = selectedImage ? selectedImage.id : null;
    stepFormData.modelId = selectedModel ? selectedModel.id : null;

    return this.stepManagementDataService.updateStepManagementById(action.stepId, stepFormData).pipe(
      map((result) => {
        ctx.dispatch(new UpdateStepManagementDataSuccess(result.data));
      }),
    );
  }

  @Action(UpdateStepManagementDataSuccess)
  updateStepManagementDataSuccess(ctx: StateContext<StepManagementStateModel>, action: UpdateStepManagementDataSuccess) {
    this.openSuccessSnackbar('Step updated successfully');

    if (this.params.instructionId) {
      ctx.dispatch(new GetInstructionManagementData());
      ctx.dispatch(new GetStepManagementData(action.stepData.id));

    } else {
      ctx.dispatch(new GetStepManagementList());
    }
  }

  @Action(CreateStepManagement)
  createStepManagement(ctx: StateContext<StepManagementStateModel>, action: CreateStepManagement) {
    let stepData = ctx.getState().stepDetailsForm.model;

    // remove empty paths
    stepData.paths = stepData.paths.filter(field => {
      return field.stepId;
    });

    return this.stepManagementDataService.createStepManagement(stepData).pipe(
      tap((result) => {
        ctx.dispatch(new CreateStepManagementSuccess(result.data));
      }),
    );
  }

  @Action(CreateStepManagementSuccess)
  createStepManagementSuccess(ctx: StateContext<StepManagementStateModel>, action: CreateStepManagementSuccess) {
    this.openSuccessSnackbar('Step created successfully');

    ctx.dispatch(new GetStepManagementList());
  }

  @Action(DeleteStep)
  deleteStep(ctx: StateContext<StepManagementStateModel>, action: DeleteStep) {

    return this.stepManagementDataService.deleteStep(action.stepId).pipe(
      tap(() => {
        ctx.dispatch(new DeleteStepSuccess());
      }),
    );
  }

  @Action(DeleteStepSuccess)
  deleteStepSuccess(ctx: StateContext<StepManagementStateModel>) {
    this.openSuccessSnackbar('Step deleted successfully');
    ctx.dispatch(new GetStepManagementList());
  }

  @Action(SetStepImage)
  setStepImage(ctx: StateContext<StepManagementStateModel>, action: SetStepImage) {
    ctx.dispatch(new UpdateFormValue({
      value: { picture: action.image },
      path: 'stepManagement.stepDetailsForm',
    }));
  }

  @Action(SetStepObject)
  setStepObject(ctx: StateContext<StepManagementStateModel>, action: SetStepObject) {
    ctx.dispatch(new UpdateFormValue({
      value: { model: action.object },
      path: 'stepManagement.stepDetailsForm',
    }));
  }

  @Action(UploadStepImage)
  uploadStepImage(ctx: StateContext<StepManagementStateModel>, action: UploadStepImage) {
    return this.stepManagementDataService.uploadStepImageFile(action.imageFile).pipe(
      tap((result) => {
        ctx.dispatch(new GetImageList());
      }),
    );
  }

  @Action(UploadStepObject)
  uploadStepObject(ctx: StateContext<StepManagementStateModel>, action: UploadStepObject) {
    return this.stepManagementDataService.uploadStepObjectFile(action.screenshotFile, action.objectFile).pipe(
      tap((result) => {
        ctx.dispatch(new GetObjectList());
      }),
    );
  }

  @Action(DeleteImage)
  deleteImage(ctx: StateContext<StepManagementStateModel>, action: DeleteImage) {
    return this.stepManagementDataService.deleteImageFile(action.imageId).pipe(
      tap(() => {
        ctx.dispatch(new GetImageList());
        this.openSuccessSnackbar('Step deleted successfully');
      }),
    );
  }

  @Action(GetImageList)
  getImageList(ctx: StateContext<StepManagementStateModel>) {
    const userId = JSON.parse(localStorage.getItem('userData')).id;
    return this.stepManagementDataService.getImageList(userId).pipe(
      tap((result) => {
        ctx.dispatch(new GetImageListSuccess(result.data));
      }),
    );
  }

  @Action(GetImageListSuccess)
  getImageListSuccess(ctx: StateContext<StepManagementStateModel>, action: GetImageListSuccess) {
    ctx.patchState({
      imageList: action.imageList,
    });

  }

  @Action(DeleteObject)
  deleteObject(ctx: StateContext<StepManagementStateModel>, action: DeleteObject) {
    return this.stepManagementDataService.deleteObjectFile(action.objectId).pipe(
      tap(() => {
        ctx.dispatch(new GetObjectList());
        this.openSuccessSnackbar('Object deleted successfully');
      }),
    );
  }

  @Action(GetObjectList)
  getObjectList(ctx: StateContext<StepManagementStateModel>) {
    const userId = JSON.parse(localStorage.getItem('userData')).id;
    return this.stepManagementDataService.getObjectList(userId).pipe(
      tap((result) => {
        ctx.dispatch(new GetObjectListSuccess(result.data));
      }),
    );
  }

  @Action(GetObjectListSuccess)
  getObjectListSuccess(ctx: StateContext<StepManagementStateModel>, action: GetObjectListSuccess) {
    ctx.patchState({
      objectList: action.objectList,
    });
  }

  private openSuccessSnackbar(message: string) {
    this.snackbarService.openSnackBar({
      data: { message },
      horizontalPosition: 'start',
      duration: 4000,
    });
  }

  private resetFormPaths(ctx, action) {
    let stepForm = {
      ...action.stepData, ...{
        paths: [
          {
            event: 'SUCCESS',
          },
          {
            event: 'FAIL',
          },
        ],
        picture: action.stepData.picture ? action.stepData.picture.name : null,
        model: action.stepData.model ? action.stepData.model.name : null,
      },
    };

    action.stepData.paths.forEach((path, i) => {
      stepForm.paths[i] = {
        id: path.id,
        stepId: path.stepId,
        event: path.event,
      } as any;
    });

    ctx.dispatch(new ResetForm({
      path: 'stepManagement.stepDetailsForm',
    }));

    return stepForm;
  }

  private resetStepData(ctx) {
    ctx.patchState({
      stepData: null,
    });
  }

}
