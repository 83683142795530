import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ModalConfig } from '../../models/modal-config';
import { ChooseImageModalModel } from '../../models/choose-image-modal.model';

@Component({
  selector: 'app-default-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class ConfirmationModalComponent {

  config: ModalConfig = {
    okButton: '',
    bodyText: 'Click on the image to attach it to the step.',
    cancelButton: '',
  };

  constructor(private activeDialog: MatDialogRef<ConfirmationModalComponent>,
              @Inject(MAT_DIALOG_DATA) public componentInput: ChooseImageModalModel) {
  }

  submit() {
    this.activeDialog.close('submitted');
  }

}
