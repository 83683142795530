import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/components/header/header.component';
import { CoreModule } from './core/core.module';
import { LayoutComponent } from './core/layouts/layout/layout.component';
import { LoginLayoutComponent } from './core/layouts/login-layout/login-layout.component';
import { LoginComponent } from './core/components/login/login.component';
import { SidebarComponent } from './core/components/sidebar/sidebar.component';
import { MaterialModule } from './material';
import { DashboardModule } from './dashboard/dashboard.module';
import { UserTokenInterceptorProvider } from './core/infrastructure/interceptors/user-token.interceptor';
import { SharedModule } from './shared/shared.module';
import { BootstrapModule } from './bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LayoutComponent,
    LoginLayoutComponent,
    LoginComponent,
    SidebarComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    CoreModule,
    DashboardModule,
    ReactiveFormsModule,
    NgxsFormPluginModule,
    SharedModule,
    BootstrapModule,
  ],
  providers: [UserTokenInterceptorProvider],
  bootstrap: [AppComponent],
})
export class AppModule {
}
