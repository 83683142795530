import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthUserStoreService } from '../../services/auth-user-store.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  userLoginForm: FormGroup = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private router: Router,
    private authUserStoreService: AuthUserStoreService,
    private fb: FormBuilder,
  ) {
  }

  login() {
    this.authUserStoreService.loginUser();
  }

}
