/* eslint-disable padded-blocks */

import { DiagramDataModel } from '../../models/bpmn-details.model';
import { ResourceModel } from '../../models/resource.model';
import { SelectedShapeModel } from '../../models/selected-shape.model';

export class AssignBPMN {
  static readonly type = '[Flow Diagram] Assign BPMN';

  constructor(public resource: ResourceModel) {
  }
}
export class AssignBPMNSuccess {
  static readonly type = '[Flow Diagram] Assign BPMN Success';

  constructor(public resource: ResourceModel) {
  }
}

export class GetBpmnPathScheme {
  static readonly type = '[Flow Diagram] Get Bpmn Path Data';

  constructor(public schemeId: number) {
  }
}

export class GetBpmnPathSchemeSuccess {
  static readonly type = '[Flow Diagram] Get Bpmn Path Data Success';

  constructor(public bpmnData: DiagramDataModel) {
  }
}

export class UpdateBpmnData {
  static readonly type = '[Flow Diagram] Update Bpmn Data';

  constructor(public xmlString: string) {
  }
}

export class UpdateBpmnDataSuccess {
  static readonly type = '[Flow Diagram] Update Diagram Data Success';

  constructor(public diagramData: DiagramDataModel) {
  }
}

export class SaveDiagramOnSaveNode {
  static readonly type = '[Bpmn Manager] Save Diagram On Save Node';
}

export class GetAllBpmnSchemes {
  static readonly type = '[Bpmn Manager] Get All Bpmn Schemes';
}

export class GetAllBpmnSchemesSuccess {
  static readonly type = '[Bpmn Manager] Get All Bpmn Schemes Success';

  constructor(public bpmnSchemes: DiagramDataModel[]) {
  }
}

export class BpmnHasChanged {
  static readonly type = '[Bpmn Manager] Bpmn Schemes Changed';

  constructor(public diagram: string) {
  }
}

export class BpmnHasChangedReset {
  static readonly type = '[Bpmn Manager] Bpmn Schemes Changed Reset';
}

export class SetSelectedShape {
  static readonly type = '[Bpmn Manager] Bpmn Set Selected Shape';

  constructor(public shape: SelectedShapeModel) {
  }
}

