import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  OnInit, Output, EventEmitter,
} from '@angular/core';

/**
 * You may include a different variant of BpmnJS:
 *
 * bpmn-viewer  - displays BPMN diagrams without the ability
 *                to navigate them
 * bpmn-modeler - bootstraps a full-fledged BPMN editor
 */
import { Observable, Subject } from 'rxjs';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { SaveDiagramOnSaveNode } from '../../../flow-diagram/store/actions/bpmn.actions';
import { BpmnService } from '../../../flow-diagram/services/bpmn.service';

@Component({
  selector: 'app-diagram',
  template: `
    <div #ref class="diagram-container"></div>
  `,
  styles: [`
    .diagram-container {
      height: 100%;
      width: 100%;
    }
  `],
})
export class DiagramComponent implements AfterContentInit, OnDestroy, OnInit {

  @ViewChild('ref', { static: true }) private el: ElementRef;

  @Input() public diagramXml$: Observable<string>;
  @Input() public save$: Observable<boolean>;
  @Input() public getBpmn$: Observable<boolean>;

  @Output() private importDone: EventEmitter<any> = new EventEmitter();
  @Output() private createElement: EventEmitter<any> = new EventEmitter();
  // @Output() private createSubProcess: EventEmitter<any> = new EventEmitter();
  @Output() private saveBpmn: EventEmitter<any> = new EventEmitter();
  // @Output() private getBpmn: EventEmitter<any> = new EventEmitter();
  @Output() private elementClick: EventEmitter<any> = new EventEmitter();
  // @Output() private elementAltClick: EventEmitter<any> = new EventEmitter();
  @Output() private bpmnHasChanged: EventEmitter<any> = new EventEmitter();
  @Output() private elementTypeChange: EventEmitter<any> = new EventEmitter();

  private unsubscribe = new Subject();

  constructor(private actions$: Actions, private bpmnService: BpmnService) {

    this.bpmnService.initBpmn();

    this.bpmnService.createEnd.subscribe((event) => {
      if (event) {
        this.createElement.emit(event.shape);
      }
    });
    this.bpmnService.elementClick.subscribe((event) => {
      if (event) {
        this.elementClick.emit(event);
      }
    });
    this.bpmnService.elementChange.subscribe((event) => {
      if (event) {
        this.bpmnHasChanged.emit(event);
      }
    });
    this.bpmnService.elementTypeChange.subscribe((event) => {
      if (event) {
        this.elementTypeChange.emit(event);
      }
    });
    this.bpmnService.elementsChange.subscribe((event) => {
      if (event) {
        this.bpmnHasChanged.emit();
      }
    });

    bpmnService.bpmnJS.on('import.done', ({ error }) => {
      if (!error) {
        const canvas = bpmnService.bpmnJS.get('canvas');
        canvas.zoom('fit-viewport');
      }
    });

  }

  ngAfterContentInit(): void {
    this.bpmnService.bpmnJS.attachTo(this.el.nativeElement);
  }

  saveXml() {
    this.bpmnService.bpmnJS.saveXML({ format: true }, (err, xml) => {
      this.saveBpmn.emit({ err, xml });
    });
  }

  ngOnInit(): void {
    this.save$.subscribe((val) => {
      if (val) {
        this.saveXml();
      }
    });

    this.diagramXml$.subscribe((xml) => {
      if (xml) {
        this.bpmnService.bpmnJS.importXML(xml, (error) => {
          if (error) {
            this.importDone.emit({
              type: 'error',
              error,
            });
          } else {
            this.importDone.emit({
              type: 'success',
            });
          }
        });
      }
    });

    this.actions$
      .pipe(
        ofActionSuccessful(SaveDiagramOnSaveNode),
        takeUntil(this.unsubscribe),
      )
      .subscribe(() => {
        this.saveXml();
      });

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.bpmnService.bpmnJS.destroy();
  }

  /**
   * Load diagram from URL and emit completion event
   */
  loadUrl() {

    // importDone i need

    // return (
    //   this.diagramXml$.pipe(
    //     catchError((err) => throwError(err)),
    //     importDiagram(this.bpmnJS),
    //   ).subscribe(
    //     (warnings) => {
    //       this.importDone.emit({
    //         type: 'success',
    //         warnings,
    //       });
    //     },
    //     (err) => {
    //       this.importDone.emit({
    //         type: 'error',
    //         error: err,
    //       });
    //     },
    //   )
    // );
  }

}
