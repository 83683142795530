import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private matRef: MatSnackBarRef<SnackBarComponent>,
  ) {
  }

  snackBarError = () => !!(this.data.message && this.data.isGlobalError)

  onSnackBarConfirm() {
    this.data.onConfirm();
    this.matRef.dismiss();
  }

  onSnackBarClose() {
    this.matRef.dismiss();
  }

}
