import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

import { ModalConfig } from '../models/modal-config';
import { DefaultModalComponent } from '../components/default-modal/default-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {

  constructor(private dialog: MatDialog) {
  }

  openModal(customConfig?: ModalConfig, options?: MatDialogConfig): MatDialogRef<any> {
    const modalRef: MatDialogRef<any> = this.dialog.open(customConfig.modalComponent, options);
    Object.assign(modalRef.componentInstance.config, customConfig);
    return modalRef;
  }

  showConfirm(modalConfig?: Partial<ModalConfig>, options?: MatDialogConfig) {
    const config: ModalConfig = {
      modalComponent: DefaultModalComponent,
      id: 'confirmation-modal',
      ...modalConfig,
    };

    if (!options) {
      options = {
        height: '178px',
        width: '584px',
      };
    }

    return this.openModal(config, options).afterClosed().pipe(
      filter((result) => !!result),
    );
  }

  getDialogRef(type: any) {
    return this.dialog.openDialogs.find((d) => d.componentInstance instanceof type);
  }

}
