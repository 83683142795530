/* eslint-disable padded-blocks */

export class LoginUser {
  static readonly type = '[Auth User] Login User';
}

export class LoginUserSuccess {
  static readonly type = '[Auth User] Login User Success';

  constructor(public userToken: string) {
  }
}

export class LogoutUser {
  static readonly type = '[Auth User] Logout User';
}

export class GetUserData {
  static readonly type = '[Auth User] Get User Data';
}

export class GetUserDataSuccess {
  static readonly type = '[Auth User] Get User Data Success';

  constructor(public userData: any) {
  }
}
