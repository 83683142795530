import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Params } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { tap } from 'rxjs/operators';

import { SnackbarService } from '../../shared/services/snack-bar.service';
import { AuthUserDataService } from '../services/auth-user-data.service';
import { GetUserData, GetUserDataSuccess, LoginUser, LoginUserSuccess, LogoutUser } from './actions/auth-user.actions';
import { RouterSelectors } from './router.selectors';

const initialStateForm = {
  model: undefined,
  dirty: false,
  status: '',
  errors: {},
};

interface AuthUserStateModel {
  loginUserForm: {
    model: any;
    dirty: boolean;
    status: string;
    errors: any;
  },
}

const defaultState: AuthUserStateModel = {
  loginUserForm: initialStateForm,
};

@State<AuthUserStateModel>({
  name: 'authUser',
  defaults: defaultState,
})

@Injectable()
export class AuthUserState {

  constructor(
    private authUserDataService: AuthUserDataService,
    private snackbarService: SnackbarService,
  ) {
  }

  @SelectSnapshot(RouterSelectors.params) routerParams: Params;

  @Action(LoginUser)
  loginUser(ctx: StateContext<AuthUserStateModel>) {
    const { loginUserForm } = ctx.getState();

    return this.authUserDataService.authUser(loginUserForm.model).pipe(
      tap((result) => {
        ctx.dispatch(new LoginUserSuccess(result.data));
      }),
    );
  }

  @Action(LoginUserSuccess)
  loginUserSuccess(ctx: StateContext<AuthUserStateModel>, action: LoginUserSuccess) {
    localStorage.setItem('userToken', action.userToken);

    ctx.dispatch(new GetUserData());
  }

  @Action(LogoutUser)
  logoutUser(ctx: StateContext<AuthUserStateModel>) {
    localStorage.clear();
    ctx.dispatch(new Navigate(['/login']));
  }

  @Action(GetUserData)
  getUserData(ctx: StateContext<AuthUserStateModel>) {
    return this.authUserDataService.getUserData().pipe(
      tap((result) => {
        ctx.dispatch(new GetUserDataSuccess(result.data));
      }),
    );
  }

  @Action(GetUserDataSuccess)
  getUserDataSuccess(ctx: StateContext<AuthUserStateModel>, action: GetUserDataSuccess) {
    localStorage.setItem('userData', JSON.stringify(action.userData));
    localStorage.setItem('plantData', JSON.stringify(action.userData.availablePlants[0]));

    ctx.dispatch(new Navigate(['/dashboard']));
  }

  private openSuccessSnackbar(message: string) {
    this.snackbarService.openSnackBar({
      data: { message },
      horizontalPosition: 'end',
      duration: 2000,
    });
  }

}
