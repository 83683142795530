import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalConfig } from '../../models/modal-config';
import { ChooseObjectModalModel } from '../../models/choose-object-modal.model';
import { StepManagementStoreService } from '../../../step-management/services/step-management-store.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-choose-object-modal',
  templateUrl: './choose-object-modal.component.html',
  styleUrls: ['./choose-object-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ChooseObjectModalComponent implements OnInit {

  @ViewChild('uploadScreenshotInput') uploadScreenshotInput: ElementRef<HTMLElement>;
  @ViewChild('uploadObjectInput') uploadObjectInput: ElementRef<HTMLElement>;

  objectList$ = this.stepStoreService.objectList$;


  stepId: number;
  config: ModalConfig = {
    okButton: '',
    bodyText: 'Click on the Object to attach it to the step.',
    cancelButton: '',
  };
  stepObjectFileForm: FormGroup = this.fb.group({
    screenshot: [null],
    screenshotSource: [null, Validators.required],
    object: [null],
    objectSource: [null, Validators.required],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public componentInput: ChooseObjectModalModel,
    private stepStoreService: StepManagementStoreService,
    private fb: FormBuilder,
  ) {

    if (this.componentInput.messages) {
      this.config = { ...this.config, ...this.componentInput.messages };
    }
  }

  deleteObject(ObjectId) {
    this.stepStoreService.deleteObject(ObjectId);
  }

  selectObject(e) {
    this.stepStoreService.setStepObject(e);
    this.componentInput.dialogRef().close();
  }

  screenshotInputChange(e) {
    if (e.target.files.length > 0) {
      const screenshot = e.target.files[0];
      this.stepObjectFileForm.patchValue({
        screenshotSource: screenshot,
      });

    }
  }
  objectInputChange(e) {
    if (e.target.files.length > 0) {
      const object = e.target.files[0];
      this.stepObjectFileForm.patchValue({
        objectSource: object
      });

    }
  }

  uploadFiles() {
    this.stepStoreService.uploadStepObject(
      this.stepObjectFileForm.value.screenshotSource,
      this.stepObjectFileForm.value.objectSource
    )
    this.stepObjectFileForm.reset();
  }

  ngOnInit(): void {
  }

}
