import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalConfig } from '../../models/modal-config';
import { ChooseAudioModalModel } from '../../models/choose-audio-modal.model';
import { StepManagementStoreService } from '../../../step-management/services/step-management-store.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-choose-audio-modal',
  templateUrl: './choose-audio-modal.component.html',
  styleUrls: ['./choose-audio-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ChooseAudioModalComponent implements OnInit {

  @ViewChild('uploadImgInput') uploadImgInput: ElementRef<HTMLElement>;

  imageList$ = this.stepStoreService.imageList$;

  stepId: number;
  config: ModalConfig = {
    okButton: '',
    bodyText: 'Click on the audio to attach it to the step.',
    cancelButton: '',
  };

  stepImageFileForm: FormGroup = this.fb.group({
    file: [null],
    fileSource: [null],
  });

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public componentInput: ChooseAudioModalModel,
    private stepStoreService: StepManagementStoreService,
  ) {

    if (this.componentInput.messages) {
      this.config = { ...this.config, ...this.componentInput.messages };
    }
  }

  onUploadImage() {
    let el: HTMLElement = this.uploadImgInput.nativeElement;
    el.click();
  }

  deleteImage(imageId) {
   this.stepStoreService.deleteImage(imageId);
  }

  selectImage(e) {
    this.stepStoreService.setStepImage(e);
    this.componentInput.dialogRef().close();
  }

  inputChange(e) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.stepImageFileForm.patchValue({
        fileSource: file
      });
      this.stepStoreService.uploadStepImage(this.stepImageFileForm.value.fileSource);
      this.stepImageFileForm.reset();
    }
  }

  ngOnInit(): void {
  }


}
