import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModalConfig } from '../../../shared/models/modal-config';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { StepManagementStoreService } from '../../services/step-management-store.service';
import { AVAILABLE_PLANTS } from '../../../shared/constants/form-constants';
import { Actions } from '@ngxs/store';
import { ModalService } from '../../../shared/services/modal.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-step-management-details',
  templateUrl: './step-management-details.component.html',
  styleUrls: ['./step-management-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StepManagementDetailsComponent implements OnInit {
  @Input() stepId: number;
  @Output() onOpenChooseImageModal: EventEmitter<any> = new EventEmitter();
  @Output() onOpenChooseObjectModal: EventEmitter<any> = new EventEmitter();
  @Output() onOpenChooseAudioModal: EventEmitter<any> = new EventEmitter();

  availablePlants = AVAILABLE_PLANTS;
  config: ModalConfig = {
    okButton: 'Save',
    bodyText: 'Setup step parameters, and dependencies.',
  };
  stepDetailsForm: FormGroup = this.fb.group({
    header: [''],
    body: [''],
    picture: [''],
    model: [''],
    audioFile: [''],
    startSubStep: [''],
    stepTests: this.fb.array([
      this.fb.group(
        {
          expectedValue: 'expval',
          stepTestDescription: 'desc',
          testPredicate: 'TEST_EQUALS',
        },
      ),
    ]),
    paths: this.fb.array([
        this.fb.group(
          {
            event: ['SUCCESS'],
            stepId: [''],
            directionId: ['1'],
          },
        ),
        this.fb.group(
          {
            event: ['FAIL'],
            stepId: [''],
            directionId: ['2'],
          },
        ),
      ],
    ),
  });

  get audioFile() {
    return this.stepDetailsForm.get('audioFile') as FormControl;
  }

  get paths() {
    return this.stepDetailsForm.get('paths') as FormArray;
  }

  get stepTests() {
    return this.stepDetailsForm.get('stepTests') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private actions$: Actions,
    private modalService: ModalService,
    private domSanitizer: DomSanitizer,
    private storeService: StepManagementStoreService,
  ) {}

  ngOnInit(): void {
    console.log(this.stepDetailsForm.controls);
  }

  chooseImage() {
    this.onOpenChooseImageModal.emit()
  }

  chooseObject() {
    this.onOpenChooseObjectModal.emit()
  }

  chooseFile() {
    this.onOpenChooseAudioModal.emit()
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  onSubmit() {
    if (this.stepDetailsForm.valid) {
        this.storeService.updateStep(this.stepId);
    }
  }

}
