import { Injectable } from '@angular/core';

import { AppConfig } from '../../core/infrastructure/services/app-config.service';
import { HttpClientService } from '../../shared/services/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataDataService {

  private get url(): string {
    return AppConfig.settings.API.AUTOMATION_STANDARD;
  }

  constructor(private httpClientService: HttpClientService) {
  }

  getPlants() {
    return this.httpClientService.get<any>(`${this.url}/reference-data/plants`);
  }

  getRoles() {
    return this.httpClientService.get<any>(`${this.url}/reference-data/user-roles`);
  }

  getPermissions() {
    return this.httpClientService.get<any>(`${this.url}/reference-data/permissions`);
  }

}
