/* eslint-disable padded-blocks */

import { InstructionModel } from '../../models/instruction.model';

export class GetInstructionManagementList {
  static readonly type = '[User Manager] Get Instruction Management List';

  constructor() {
  }
}

export class GetInstructionManagementListSuccess {
  static readonly type = '[Instruction Manager] Get Instruction Management List Success';

  constructor(public instructionList: InstructionModel[]) {
  }
}

export class GetInstructionManagementData {
  static readonly type = '[Instruction Manager] Get Instruction Management Data';

  constructor() {
  }
}

export class GetInstructionManagementDataSuccess {
  static readonly type = '[Instruction Manager] Get Instruction Management Data Success';

  constructor(public instructionData: InstructionModel) {
  }
}

export class UpdateInstructionManagementData {
  static readonly type = '[Instruction Manager] Update Instruction Management Data';

  constructor(public instructionId: number) {
  }
}

export class UpdateInstructionManagementDataSuccess {
  static readonly type = '[Instruction Manager] Update Instruction Management Data Success';

  constructor(public instructionData: InstructionModel) {
  }
}

export class CreateInstructionManagement {
  static readonly type = '[Instruction Manager] Create Instruction Management Data';
}

export class CreateInstructionManagementSuccess {
  static readonly type = '[Instruction Manager] Create Instruction Management Data Success';

  constructor(public instructionData: InstructionModel) {
  }
}

export class DeleteInstruction {
  static readonly type = '[Instruction Manager] DeleteInstruction';

  constructor(public instructionId: number) {
  }
}

export class DeleteInstructionSuccess {
  static readonly type = '[Instruction Manager] DeleteInstruction Success';
}

export class ActivateInstruction {
  static readonly type = '[Instruction Manager] Activate Instruction';

  constructor(public instructionId: number) {
  }
}


export class ActivateInstructionSuccess {
  static readonly type = '[Instruction Manager] Activate Instruction Success';
}



