import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ModalConfig } from '../../models/modal-config';

@Component({
  selector: 'app-default-modal',
  templateUrl: './default-modal.component.html',
  styleUrls: ['./default-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class DefaultModalComponent {

  config: ModalConfig = {
    modalComponent: undefined,
    okButton: 'OK',
    cancelButton: undefined,
  };

  constructor(private activeDialog: MatDialogRef<DefaultModalComponent>) {
  }

  submitFn() {
    this.activeDialog.close('submitted');
  }

}
