import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserDataModel } from '../../core/models/user-data.model';

@Directive({ selector: '[hasPermissions]'})
export class HasPermissions {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }

  @Input() set hasPermissions(permission: string) {
    const userData: UserDataModel = JSON.parse(localStorage.getItem('userData'));
    const hasPermissions = userData.userRole.permissions.includes(permission);

    if (hasPermissions) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      // this.hasView = true;
    } else {
      this.viewContainer.clear();
      // this.hasView = false;
    }
  }
}
