export const USER_ROLES = [
  {
    id: 1,
    name: 'ROLE_SYSTEM_ADMIN',
    displayName: 'System administrator',
    permissions: ['BPMN_WRITE', 'RECORDER_SERVICE_WRITE', 'RECORDER_SERVICE_READ', 'BPMN_READ', 'SENSORS_SERVICES_WRITE', 'SENSORS_SERVICES_READ']
  },{
    id: 2,
    name: 'CLIENT',
    displayName: 'Client',
    permissions: ['BPMN_WRITE', 'RECORDER_SERVICE_WRITE', 'RECORDER_SERVICE_READ', 'BPMN_READ', 'SENSORS_SERVICES_WRITE', 'SENSORS_SERVICES_READ']
  },
];

export const AVAILABLE_PLANTS = [
  {
    id: 2,
    name: 'Env Plant Name2',
    organization: {
      id: 1,
      name: 'Awesome organization'
    }
  },
  {
    id: 1,
    name: 'Env Plant Name',
    organization: {
      id: 1,
      name: 'Awesome organization'
    }
  }
];
