import { Injectable } from '@angular/core';
import { Selector, State } from '@ngxs/store';

interface DashboardStateModel {
  something: string;
}

const defaultState: DashboardStateModel = {
  something: null,
};

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: defaultState,
})

@Injectable()
export class DashboardState {

  @Selector()
  static some(state: DashboardStateModel) {
    return state.something;
  }

}
