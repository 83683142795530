import { Injectable } from '@angular/core';

import { AppConfig } from '../../core/infrastructure/services/app-config.service';
import { HttpClientService } from '../../shared/services/http-client.service';
import { LoginUserModel } from '../models/login-user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthUserDataService {

  private get url(): string {
    return AppConfig.settings.API.AUTH_SERVICE;
  }

  constructor(private httpClientService: HttpClientService) {
  }

  authUser(user: LoginUserModel) {
    return this.httpClientService.post<any>(`${this.url}/auth`,{email: user.email, password: user.password});
  }

  getUserData() {
    return this.httpClientService.get<any>(`${this.url}/auth/me`);
  }

}
