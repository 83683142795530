import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LOCAL_STORAGE } from '../constants/localStorage';
import { AuthUserStoreService } from '../../core/services/auth-user-store.service';

@Injectable()
export class RoleGuardService implements CanActivate {

  constructor(private router: Router, private authUserStoreService: AuthUserStoreService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER_DATA));
    const { userPermissions } = route.data;
    const hasPermissions = userData.userRole.permissions.some(
      (p) => userPermissions.includes(p)
    );

    if (hasPermissions) {
      return true;
    }

    this.authUserStoreService.logoutUser();

    return false;
  }

}
