import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class UserTokenInterceptor implements HttpInterceptor {

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const { userToken } = localStorage;
    const { plantData } = localStorage;

    if (userToken) {
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + userToken,
        },
      });
    }

    if (plantData) {
      request = request.clone({
        setHeaders: {
          'PlantId' : JSON.parse(plantData).id.toString(),
        },
      });
    }

    return next.handle(request);
  }

}

export const UserTokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: UserTokenInterceptor,
  multi: true,
};
