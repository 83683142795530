import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { PlantModel } from '../../models/plant.model';
import { UserDataModel } from '../../models/user-data.model';
import { AuthUserStoreService } from '../../services/auth-user-store.service';
import { LOCAL_STORAGE } from '../../../shared/constants/localStorage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  plantData: PlantModel = JSON.parse(localStorage.getItem(LOCAL_STORAGE.PLANT_DATA));
  userData: UserDataModel = JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER_DATA));

  constructor(
    private router: Router,
    private authUserStoreService: AuthUserStoreService,
  ) {
  }

  changePlant(plant) {
    localStorage.setItem(LOCAL_STORAGE.PLANT_DATA, JSON.stringify(plant));
    location.reload();
  }

  logout() {
    this.authUserStoreService.logoutUser();
  }

}
