import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ErrorCodeEnum } from '../models/error-code.enum';
import { ErrorDataModel } from '../models/error-data.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {

  parseServerErrors(error: HttpErrorResponse): string[] {
    if (error.status === ErrorCodeEnum.DEFAULT_SERVER_ERROR) {
      const genericErrorMessage = `${error.statusText}. Please try again`;

      return [genericErrorMessage];
    }

    if (error.status === ErrorCodeEnum.UNAUTHORIZED) {
      return ['Unauthorized!'];
    }

    if (error.status === ErrorCodeEnum.FORBIDDEN) {
      return ['The action that you requested is Forbidden.'];
    }

    if (error.status === ErrorCodeEnum.BAD_REQUEST) {
      return ['Unable to process the request.'];
    }

    const errorDataModel = error.error.errorData as ErrorDataModel;
    const beErrors = errorDataModel.errors;
    const beErrorMessages: string[] = beErrors.map((err) => `${err.field ? err.field : ''} ${err.defaultMessage}`);

    return beErrorMessages;
  }

}
