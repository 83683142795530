import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import {
  CreateStepManagement, DeleteImage, DeleteObject,
  DeleteStep, GetImageList, GetObjectList,
  GetStepManagementData,
  GetStepManagementList, ResetStepManagementData, SetStepImage, SetStepObject,
  UpdateStepManagementData, UploadStepImage, UploadStepObject,
} from '../store/actions/step-management.actions';
import { StepManagementState } from '../store/step-management.state';
import { StepModel } from '../models/step.model';
import { PermissionModel } from '../../core/models/permission.model';
import { ImageModel } from '../models/image.model';
import { ObjectModel } from '../models/object.model';

@Injectable({
  providedIn: 'root',
})
export class StepManagementStoreService {

  @Select(StepManagementState.stepManagementList) stepManagementList$: Observable<StepModel[]>;
  @Select(StepManagementState.imageList) imageList$: Observable<ImageModel[]>;
  @Select(StepManagementState.objectList) objectList$: Observable<ObjectModel[]>;
  @Select(StepManagementState.permissions) permissions$: Observable<PermissionModel[]>;
  @Select(StepManagementState.stepData) stepData$: Observable<StepModel>;
  @Select(StepManagementState.stepDiagramXml) stepDiagramXml$: Observable<string>;

  @Dispatch()
  getStepManagementList = () => new GetStepManagementList();

  @Dispatch()
  getImageList = () => new GetImageList();

  @Dispatch()
  getObjectList = () => new GetObjectList();

  @Dispatch()
  getStepManagementData = (stepId: number) => new GetStepManagementData(stepId);

  @Dispatch()
  resetStepManagementData = () => new ResetStepManagementData();

  @Dispatch()
  updateStep = (stepId: number) => new UpdateStepManagementData(stepId);

  @Dispatch()
  createStep = () => new CreateStepManagement();

  @Dispatch()
  deleteStep = (stepId: number) => new DeleteStep(stepId);

  @Dispatch()
  setStepImage = (image: string) => new SetStepImage(image);

  @Dispatch()
  setStepObject = (object: string) => new SetStepObject(object);

  @Dispatch()
  uploadStepImage = (imageFile: any) => new UploadStepImage(imageFile);

  @Dispatch()
  deleteImage = (imageId: any) => new DeleteImage(imageId);

  @Dispatch()
  deleteObject = (imageId: any) => new DeleteObject(imageId);

  @Dispatch()
  uploadStepObject = (screenshotFile: any, objectFile: any) => new UploadStepObject(screenshotFile, objectFile);

}

