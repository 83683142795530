import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';

import { Params } from '@angular/router';
import { RouterSelectors } from '../store/router.selectors';
import { LoginUser, LogoutUser } from '../store/actions/auth-user.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthUserStoreService {
  @SelectSnapshot(RouterSelectors.params) routerParams: Params;

  @Dispatch()
  loginUser = () => new LoginUser();
  @Dispatch()
  logoutUser = () => new LogoutUser();

}

