import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { UserDataModel } from '../../models/user-data.model';
import { LOCAL_STORAGE } from '../../../shared/constants/localStorage';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  userData: UserDataModel = JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER_DATA));

  constructor(private location: Location) {
  }

  isLoginPage(): boolean {
    return this.location.path() === '/login';
  }

}
