import { NgModule } from '@angular/core';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


const modules = [
  NgbTooltipModule
];

@NgModule({
  imports: modules,
  providers: [],
  exports: modules,
})
export class BootstrapModule {
}
