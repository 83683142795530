import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DiagramDataModel } from '../../../flow-diagram/models/bpmn-details.model';

@Component({
  selector: 'app-bpmn-details-header',
  templateUrl: './bpmn-details-header.component.html',
  styleUrls: ['./bpmn-details-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpmnDetailsHeaderComponent {

  @Input() public bpmnData: Observable<DiagramDataModel>;

  constructor(private fb: FormBuilder) {
  }

  bpmnForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    description: [''],
  });

}
