import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { PROVIDERS } from './infrastructure.providers';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [PROVIDERS],
})
export class InfrastructureModule { }
