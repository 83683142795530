import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Params } from '@angular/router';
import { tap } from 'rxjs/operators';

import { RouterSelectors } from './router.selectors';
import { PlantModel } from '../models/plant.model';
import { ReferenceDataDataService } from '../services/reference-data-data.service';
import {
  GetPermissions,
  GetPermissionsSuccess,
  GetPlants,
  GetPlantsSuccess,
  GetRoles,
  GetRolesSuccess,
} from './actions/reference-data.actions';
import { PermissionModel } from '../models/permission.model';
import { RoleModel } from '../../role-management/models/role.model';

interface ReferenceDataStateModel {
  plants: PlantModel[],
  roles: RoleModel[],
  permissions: PermissionModel[],
}

const defaultState: ReferenceDataStateModel = {
  plants: null,
  roles: null,
  permissions: null,
};

@State<ReferenceDataStateModel>({
  name: 'referenceData',
  defaults: defaultState,
})

@Injectable()
export class ReferenceDataState {

  constructor(
    private referenceDataService: ReferenceDataDataService,
  ) {
  }

  @SelectSnapshot(RouterSelectors.params) routerParams: Params;

  @Selector()
  static plants(state: ReferenceDataStateModel) {
    return state.plants;
  }

  @Selector()
  static roles(state: ReferenceDataStateModel) {
    return state.roles;
  }

  @Selector()
  static permissions(state: ReferenceDataStateModel) {
    return state.permissions;
  }

  @Selector()
  static permissionsSnapshot(state: ReferenceDataStateModel) {
    return state.permissions;
  }

  @Action(GetPlants)
  getPlants(ctx: StateContext<ReferenceDataStateModel>) {
    return this.referenceDataService.getPlants().pipe(
      tap((result) => {
        ctx.dispatch(new GetPlantsSuccess(result.data));
      }),
    );
  }

  @Action(GetPlantsSuccess)
  getPlantsSuccess(ctx: StateContext<ReferenceDataStateModel>, action: GetPlantsSuccess) {
    ctx.patchState({
      plants: action.plants
    });
  }

  @Action(GetRoles)
  getRoles(ctx: StateContext<ReferenceDataStateModel>) {
    return this.referenceDataService.getRoles().pipe(
      tap((result) => {
        ctx.dispatch(new GetRolesSuccess(result.data));
      }),
    );
  }

  @Action(GetRolesSuccess)
  getRolesSuccess(ctx: StateContext<ReferenceDataStateModel>, action: GetRolesSuccess) {
    ctx.patchState({
      roles: action.roles
    });
  }

  @Action(GetPermissions)
  getPermissions(ctx: StateContext<ReferenceDataStateModel>) {
    return this.referenceDataService.getPermissions().pipe(
      tap((result) => {
        ctx.dispatch(new GetPermissionsSuccess(result.data));
      }),
    );
  }

  @Action(GetPermissionsSuccess)
  getPermissionsSuccess(ctx: StateContext<ReferenceDataStateModel>, action: GetPermissionsSuccess) {
    ctx.patchState({
      permissions: action.permissions
    });
  }

}
