import {RouterModule, Routes} from "@angular/router";
import {DashboardComponent} from "./containers/dashboard/dashboard.component";
import {NgModule} from "@angular/core";

const routes: Routes = [
  {path: '', component: DashboardComponent, pathMatch: 'full'},
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export  class DashboardRoutingModule {

}
