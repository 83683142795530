import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse, HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiResponseModel } from '../models/api-response.model';
import { ErrorsService } from '../../core/infrastructure/services/errors.service';
import { SnackbarService } from './snack-bar.service';
import { AuthUserStoreService } from '../../core/services/auth-user-store.service';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient,
              private errorsService: ErrorsService,
              private authStoreService: AuthUserStoreService,
              private snackService: SnackbarService) {
  }

  public get<T>(url: string, params?: any): Observable<ApiResponseModel<T>> {
    return this.http.get<ApiResponseModel<T>>(url, {
      params,
      headers: this.headers,
    })
      .pipe(
        catchError((error) => this.handleError(error, true)),
      );
  }

  public post<T>(url: string, params: any): Observable<ApiResponseModel<T>> {
    return this.http.post<ApiResponseModel<T>>(url, params, { headers: this.headers })
      .pipe(
        catchError((error) => this.handleError(error)),
      );
  }

  public postFile<T>(url: string, params: any, headers): Observable<ApiResponseModel<T>> {
    return this.http.post<ApiResponseModel<T>>(url, params, { headers: headers })
      .pipe(
        catchError((error) => this.handleError(error)),
      );
  }

  public put<T>(url: string, params: any): Observable<ApiResponseModel<T>> {
    return this.http.put<ApiResponseModel<T>>(url, params, { headers: this.headers })
      .pipe(
        catchError((error) => this.handleError(error)),
      );
  }

  public patch<T>(url: string, params?: any): Observable<ApiResponseModel<T>> {
    return this.http.patch<ApiResponseModel<T>>(url, params, { headers: this.headers })
      .pipe(
        catchError((error) => this.handleError(error)),
      );
  }

  public delete<T>(url: string, params?: any): Observable<ApiResponseModel<T>> {
    return this.http.delete<ApiResponseModel<T>>(url, {
      params,
      headers: this.headers,
    })
      .pipe(
        catchError((error) => this.handleError(error)),
      );
  }

  public getFile(url: string): Observable<any> {
    return this.http.get<any>(url, {
      responseType: 'blob' as 'json',
      observe: 'response',
    })
      .pipe(
        catchError((error) => this.handleError(error, true)),
      );
  }

  public handleError(error: HttpErrorResponse, showSnackbar?: boolean) {
    let errorMessage = '';

    if (error.status == 403) {
      this.authStoreService.logoutUser();
      return;
    }

    if (error.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      const serverMessages = this.errorsService.parseServerErrors(
        error,
      );

      if (serverMessages && serverMessages.length) {
        if (showSnackbar) {
          this.snackService.openSnackBar({
            horizontalPosition: 'start',
            verticalPosition: 'top',
            panelClass: 'global-handler',
            data: {
              message: serverMessages,
              isGlobalError: true,
            },
          });
        } else {
          this.snackService.openSnackBar({
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: 'global-handler',
            data: {
              message: serverMessages,
              isGlobalError: true,
            },
          });
          // this.modalService.showConfirm({
          //   id: 'confirmation-modal',
          //   headerText: 'Errors',
          //   bodyText: `
          //      ${serverMessages.map((serverMessage) => `${serverMessage}`).join('<br />')}
          //   `,
          //   okButton: 'Ok',
          // }, {
          //   height: '178px',
          //   width: '584px',
          // });
        }
      }
    }

    return throwError(errorMessage);
  }

  // private buildParams(source: Record<string, any>) {
  //   if (!source || typeof source !== 'object') {
  //     return null;
  //   }
  //
  //   let target = new HttpParams();
  //
  //   Object.keys(source).forEach((key: string) => {
  //     const value = source[key];
  //
  //     if (typeof value !== 'undefined' && value !== null) {
  //       target = target.append(key, value.toString());
  //     }
  //   });
  //
  //   return target;
  // }

}
