import { Injectable } from '@angular/core';

import * as BpmnJS from 'bpmn-js/dist/bpmn-modeler.production.min';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BpmnService {

  public bpmnJS: BpmnJS;
  public createEnd: BehaviorSubject<any> = new BehaviorSubject(null);
  public elementClick: BehaviorSubject<any> = new BehaviorSubject(null);
  public elementChange: BehaviorSubject<any> = new BehaviorSubject(null);
  public elementTypeChange: BehaviorSubject<any> = new BehaviorSubject(null);
  public elementsChange: BehaviorSubject<any> = new BehaviorSubject(null);

  initBpmn() {

    this.bpmnJS = new BpmnJS({
      bpmnViewer: {
        container: '#canvas',
      },
      bpmnRenderer: {
        palleteWidth: '10px',
      },
    });

    const eventBus = this.bpmnJS.get('eventBus');
    // const esventBus = this.bpmnJS.get();
    // console.log(eventBus, 22222222);

    eventBus.on('create.end', (event) => {
      this.createEnd.next(event);
    });

    eventBus.on('element.click', (event) => {
      const ev: any = window.event;
      const newEvent = {
        element: event.element,
        navigate: !!ev.altKey,
      };

      this.elementClick.next(newEvent);
    });

    eventBus.on('element.changed', (event) => {
      if (event.element.type === 'bpmn:SubProcess' && event.element.collapsed !== false) {
        this.elementChange.next(event);
      }
      if (event.element.type === 'bpmn:StartEvent') {
        this.elementTypeChange.next(event);
      }
    });

    eventBus.on('elements.changed', () => {
      this.elementsChange.next(true);
    });

  }

}
