import { APP_INITIALIZER, Provider } from '@angular/core';

import { AppConfig } from './services/app-config.service';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export const PROVIDERS: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [AppConfig],
    multi: true,
  },
  AppConfig,
];
