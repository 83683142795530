import { Injectable } from '@angular/core';

import { StepModel } from '../models/step.model';
import { AppConfig } from '../../core/infrastructure/services/app-config.service';
import { HttpClientService } from '../../shared/services/http-client.service';
import { ImageModel } from '../models/image.model';
import { ObjectModel } from '../models/object.model';

@Injectable({
  providedIn: 'root',
})
export class StepManagementDataService {

  private get url(): string {
    return AppConfig.settings.API.INSTRUCTOR;
  }

  constructor(private httpClientService: HttpClientService) {
  }

  getStepManagementListData(userId: number) {
    return this.httpClientService.get<StepModel[]>(`${this.url}/steps`);
  }


  getStepManagementById(stepId: number) {
    return this.httpClientService.get<StepModel>(`${this.url}/steps/${stepId}`);
  }

  updateStepManagementById(stepId: number, stepData: StepModel) {
    return this.httpClientService.put<StepModel>(`${this.url}/steps/${stepId}`, stepData);
  }

  createStepManagement(stepData: StepModel) {
    return this.httpClientService.post<StepModel>(`${this.url}/steps`, stepData);
  }

  deleteStep(stepId: number) {
    return this.httpClientService.delete<number>(`${this.url}/steps/${ stepId }`);
  }

  getImageList(userId: number) {
    return this.httpClientService.get<ImageModel[]>(`${this.url}/pictures`);
  }

  uploadStepImageFile(imageFile: any) {
    let imageFileData:FormData = new FormData();
    imageFileData.append('picture', imageFile, imageFile.name);
    imageFileData.append('name', imageFile.name);
    imageFileData.append('description', imageFile.name);

    let headers = new Headers();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.httpClientService.postFile<any>(`${this.url}/pictures`,
      imageFileData, { headers });
  }

  deleteImageFile(imageId: number) {
    return this.httpClientService.delete<number>(`${this.url}/pictures/${ imageId }`);
  }

  getObjectList(userId: number) {
    return this.httpClientService.get<ObjectModel[]>(`${this.url}/models`);
  }

  uploadStepObjectFile(screenshotFile: any, objectFile: any) {
    let imageFileData:FormData = new FormData();
    imageFileData.append('picture', screenshotFile, screenshotFile.name);
    imageFileData.append('model', objectFile, objectFile.name);
    imageFileData.append('name', objectFile.name);
    imageFileData.append('description', objectFile.name);

    let headers = new Headers();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.httpClientService.postFile<any>(`${this.url}/models`,
      imageFileData, { headers });
  }

  deleteObjectFile(imageId: number) {
    return this.httpClientService.delete<number>(`${this.url}/models/${ imageId }`);
  }

}
