import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AppConfigModel } from '../models/app-config.model';

@Injectable()
export class AppConfig {

  static settings: AppConfigModel;

  load() {
    const jsonFile = `assets/config/config.${environment.envName}.json`;
    return new Promise<void>((resolve, reject) => {
      // Use fetch to avoid http interceptors
      fetch(jsonFile).then((response: Response) => {
        response.json().then((data) => {
          AppConfig.settings = data;
          return data;
        });
        resolve();
      }).catch((response: any) => {
        reject(new Error(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`));
      });
    });
  }

}
