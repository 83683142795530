import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/components/login/login.component';
import { LayoutComponent } from './core/layouts/layout/layout.component';
import { LoginLayoutComponent } from './core/layouts/login-layout/login-layout.component';
import { RoleGuardService } from './shared/guards/role-guard-service';
import { AuthGuardService } from './shared/guards/auth-guard-service';
import { PERMISSIONS } from './shared/constants/permissions';

const routes: Routes = [

  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    component: LayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./dashboard/dashboard.module')
        .then((m) => m.DashboardModule),
    }],
  },
  {
    path: 'service',
    canActivate: [AuthGuardService],
    component: LayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./service-manager/service-manager.module')
        .then((m) => m.ServiceManagerModule),
    }],
  },
  {
    path: 'user-management',
    pathMatch: 'prefix',
    component: LayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./user-management/user-management-list.module')
        .then((m) => m.UserManagementListModule),
    }],
  },
  {
    path: 'role-management',
    pathMatch: 'prefix',
    component: LayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./role-management/role-management-list.module')
        .then((m) => m.RoleManagementListModule),
    }],
  },
  {
    path: 'step-management',
    pathMatch: 'prefix',
    component: LayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./step-management/step-management-list.module')
        .then((m) => m.StepManagementListModule),
    }],
  },
  {
    path: 'instruction-management',
    pathMatch: 'prefix',
    component: LayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./instruction-management/instruction-management-list.module')
        .then((m) => m.InstructionManagementListModule),
    }],
  },
  {
    path: 'instruction-wizard',
    pathMatch: 'prefix',
    component: LayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./instruction-wizard/instruction-wizard-list.module')
        .then((m) => m.InstructionWizardListModule),
    }],
  },
  {
    path: 'profile',
    component: LayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./profile/profile.module')
        .then((m) => m.ProfileModule),
    }],
  },
  {
    path: 'idf',
    component: LayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./idf/idf.module')
        .then((m) => m.IdfModule),
    }],
  },
  {
    path: 'recorder',
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      userPermissions: [
        PERMISSIONS.RECORDER_SERVICE_WRITE,
        PERMISSIONS.RECORDER_SERVICE_READ,
      ],
    },
    component: LayoutComponent,
    pathMatch: 'prefix',
    children: [{
      path: '',
      loadChildren: () => import('./audio-recorder/recorder.module')
        .then((m) => m.RecorderModule),
    }],
  },
  {
    path: 'login',
    component: LoginLayoutComponent,
    children: [{
      path: '',
      component: LoginComponent,
    }],
  },
  {
    path: 'diagram',
    canActivate: [AuthGuardService],
    pathMatch: 'prefix',
    component: LayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./flow-diagram/flow-diagram.module')
        .then((m) => m.FlowDiagramModule),
    }, {
      path: ':schemeId',
      loadChildren: () => import('./flow-diagram/flow-diagram.module')
        .then((m) => m.FlowDiagramModule),
    }, {
      path: 'logs/:schemeId',
      loadChildren: () => import('./logs/logs.module')
        .then((m) => m.LogsModule),
    }],
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        useHash: true,
        // preloadingStrategy: PreloadAllModules,
      })],
  exports: [RouterModule],
  providers: [
    RoleGuardService,
    AuthGuardService,
  ],
})
export class AppRoutingModule {
}
