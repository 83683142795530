import { Subject } from 'rxjs';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { tag } from 'rxjs-spy/operators';

import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

@Injectable({ providedIn: 'root' })
export class SnackbarService implements OnDestroy {

  private snackBarRef: MatSnackBarRef<SnackBarComponent>;
  private msgQueue = [];
  private isInstanceVisible = false;
  private destroy$ = new Subject();

  constructor(private snackBar: MatSnackBar, private ngZone: NgZone) {
  }

  showNext() {
    if (this.msgQueue.length === 0) {
      return;
    }

    const message = this.msgQueue.shift();
    this.isInstanceVisible = true;

    this.ngZone.run(() => {
      this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
        data: message.data,
        horizontalPosition: message.horizontalPosition || 'start',
        panelClass: message.panelClass,
        verticalPosition: message.verticalPosition || 'bottom',
        duration: message.duration,
      });
    });

    this.snackBarRef.afterDismissed()
      .pipe(
        tag('snackBar_afterDismissed'),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.isInstanceVisible = false;
        this.showNext();
      });
  }

  openSnackBar(config?: MatSnackBarConfig): void {
    if (config) {
      this.msgQueue.push(config);
    }
    if (!this.isInstanceVisible) {
      this.showNext();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
