/* eslint-disable padded-blocks */

import { PlantModel } from '../../models/plant.model';
import { PermissionModel } from '../../models/permission.model';
import { RoleModel } from '../../../role-management/models/role.model';

export class GetPlants {
  static readonly type = '[Reference Data] Get Plants';
}

export class GetPlantsSuccess {
  static readonly type = '[Reference Data] Get Plants Success';

  constructor(public plants: PlantModel[]) {
  }
}

export class GetRoles {
  static readonly type = '[Reference Data] Get Roles';
}

export class GetRolesSuccess {
  static readonly type = '[Reference Data] Get Roles Success';

  constructor(public roles: RoleModel[]) {
  }
}

export class GetPermissions {
  static readonly type = '[Reference Data] Get Permissions';
}

export class GetPermissionsSuccess {
  static readonly type = '[Reference Data] Get Permissions Success';

  constructor(public permissions: PermissionModel[]) {
  }
}
