import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { MaterialModule } from '../material';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { DiagramComponent } from './components/diagram/diagram.component';
import { BpmnDetailsHeaderComponent } from './components/bpmn-details-header/bpmn-details-header.component';
import { HasPermissions } from './directives/has-permissions-directive';
import { DefaultModalComponent } from './components/default-modal/default-modal.component';
import { StepManagementDetailsComponent } from '../step-management/components/step-management-details/step-management-details.component';
import { ChooseImageModalComponent } from './components/choose-image-modal/choose-image-modal.component';
import { ChooseObjectModalComponent } from './components/choose-object-modal/choose-object-modal.component';
import { ChooseAudioModalComponent } from './components/choose-audio-modal/choose-audio-modal.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsFormPluginModule,
  ],
  declarations: [
    SnackBarComponent,
    DiagramComponent,
    BpmnDetailsHeaderComponent,
    HasPermissions,
    DefaultModalComponent,
    StepManagementDetailsComponent,
    ChooseImageModalComponent,
    ChooseObjectModalComponent,
    ChooseAudioModalComponent,
    ConfirmationModalComponent,
  ],
  exports: [DiagramComponent, BpmnDetailsHeaderComponent, HasPermissions, StepManagementDetailsComponent],
  entryComponents: [ChooseImageModalComponent, ChooseObjectModalComponent, ChooseAudioModalComponent],
})
export class SharedModule {

}
