import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChartsComponent implements OnInit {

  baseChart = {
    data: [
      ['June', 20],
      ['July', 100],
      ['Aug.', 50],
      ['Sept.', 130],
      ['Nov.', 70],
    ],
    options: {
      is3D: false,
      width: 350,
    },
  };

  pieChart = {
    data: [
      ['Gem alerts', 30],
      ['Zone alerts', 50],
      ['Malfunctions', 50],
      ['Issues', 100],
    ],
    options: {
      is3D: false,
      width: 350,
    },
  };

  constructor() {
  }

  ngOnInit(): void {
  }

}

