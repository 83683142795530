import { Component, ViewEncapsulation } from '@angular/core';

export interface PeriodicElement {
  id: string;
  title: string;
  status: string;
  field: string;
  date: string;
  time: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { id: '1', title: 'Gem detected', status: 'PENDING', field: 'E', date: '01/01/2020', time: '11:45' },
  { id: '2', title: 'Danger zone activity', status: 'PENDING', field: 'H', date: '08/16/2020', time: '12:35' },
  { id: '3', title: 'Gem detected', status: 'CLOSED', field: 'E', date: '01/01/2020', time: '11:40' },
  { id: '4', title: 'Danger zone activity', status: 'PENDING', field: 'H', date: '06/13/2020', time: '10:35' },
  { id: '5', title: 'Gem detected', status: 'CLOSED', field: 'O', date: '08/16/2020', time: '01:20' },
  { id: '6', title: 'Malfunction', status: 'RESOLVED', field: 'T', date: '10/23/2020', time: '10:35' },
  { id: '7', title: 'Gem detected', status: 'PENDING', field: 'H', date: '08/16/2020', time: '01:25' },
  { id: '8', title: 'Malfunction', status: 'RESOLVED', field: 'T', date: '10/26/2020', time: '12:35' },

];

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogsComponent {

  displayedColumns: string[] = ['id', 'title', 'status', 'field', 'date', 'time'];
  dataSource = ELEMENT_DATA;

}
