export enum ErrorCodeEnum {
  DEFAULT_SERVER_ERROR = 0,
  SUCCESS = 200,
  NO_CONTENT = 204,
  PARTIAL_CONTENT = 206,
  MOVED_PERMANENTLY = 301,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  CONFLICT = 409,
  RESOURCE_LOCKED = 423,
  SERVER_ERROR = 500,
  GATEWAY_TIMEOUT = 504,
  CLOUDFLARE_TIMEOUT = 524
}
