import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import {
  NgxsRouterPluginModule, RouterStateSerializer,
} from '@ngxs/router-plugin';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { InfrastructureModule } from './infrastructure/infrastructure.module';
import { CustomRouterStateSerializer } from './store/router-state.serializer';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { AuthUserState } from './store/auth-user.state';
import { ReferenceDataState } from './store/reference-data.state';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    InfrastructureModule,
    NgxsModule.forRoot([AuthUserState, ReferenceDataState]),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ name: 'IBPMS DevTools' }),
    NgxsDispatchPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot(),
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
  ],
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only.',
      );
    }
  }

}
