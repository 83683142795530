/* eslint-disable padded-blocks */

import { StepModel } from '../../models/step.model';
import { ImageModel } from '../../models/image.model';
import { ObjectModel } from '../../models/object.model';

export class ResetStepManagementData {
  static readonly type = '[User Manager] Reset Step Management Data';

  constructor() {
  }
}

export class GetStepManagementList {
  static readonly type = '[User Manager] Get Step Management List';

  constructor() {
  }
}

export class GetStepManagementListSuccess {
  static readonly type = '[Step Manager] Get Step Management List Success';

  constructor(public stepList: StepModel[]) {
  }
}

export class GetStepManagementData {
  static readonly type = '[Step Manager] Get Step Management Data';

  constructor(public stepId: number) {
  }
}

export class GetStepManagementDataSuccess {
  static readonly type = '[Step Manager] Get Step Management Data Success';

  constructor(public stepData: StepModel) {
  }
}

export class UpdateStepManagementData {
  static readonly type = '[Step Manager] Update Step Management Data';

  constructor(public stepId: number) {
  }
}

export class UpdateStepManagementDataSuccess {
  static readonly type = '[Step Manager] Update Step Management Data Success';

  constructor(public stepData: StepModel) {
  }
}

export class CreateStepManagement {
  static readonly type = '[Step Manager] Create Step Management Data';
}

export class CreateStepManagementSuccess {
  static readonly type = '[Step Manager] Create Step Management Data Success';

  constructor(public stepData: StepModel) {
  }
}

export class DeleteStep {
  static readonly type = '[Step Manager] DeleteStep';

  constructor(public stepId: number) {
  }
}

export class DeleteStepSuccess {
  static readonly type = '[Step Manager] DeleteStep Success';
}

export class SetStepImage {
  static readonly type = '[Step Manager] Set Step Image';

  constructor(public image: string) {
  }
}

export class UploadStepImage {
  static readonly type = '[Step Manager] Upload Step Image';

  constructor(public imageFile: any) {
  }
}

export class DeleteImage {
  static readonly type = '[Step Manager] Delete Image';

  constructor(public imageId: number) {
  }
}

export class DeleteObject {
  static readonly type = '[Step Manager] Delete Object';

  constructor(public objectId: number) {
  }
}

export class UploadStepObject {
  static readonly type = '[Step Manager] Upload Step Object';

  constructor(public screenshotFile: any, public objectFile: any) {
  }
}

export class SetStepObject {
  static readonly type = '[Step Manager] Set Step Object';

  constructor(public object: string) {
  }
}

export class GetImageList {
  static readonly type = '[User Manager] Get Image List';

  constructor() {
  }
}

export class GetImageListSuccess {
  static readonly type = '[Step Manager] Get Image List Success';

  constructor(public imageList: ImageModel[]) {
  }
}

export class GetObjectList {
  static readonly type = '[User Manager] Get Object List';

  constructor() {
  }
}

export class GetObjectListSuccess {
  static readonly type = '[Step Manager] Get Object List Success';

  constructor(public objectList: ObjectModel[]) {
  }
}



